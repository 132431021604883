<template>
    <footer class="page__footer">
        <div class="contact-button">
            <router-link class="button--contact" to="/contentKontakt">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                     y="0px"
                     viewBox="0 0 25 25" style="enable-background:new 0 0 25 25;" xml:space="preserve">
                        <g>
                            <path d="M23.8,17.9L23,17.4c-1.4-1-2.8-1.9-3.8-2.4c-0.3-0.2-0.8-0.3-1.3-0.3c-0.5,0-1.2,0.1-1.8,0.7c-0.4,0.3-0.6,0.6-0.8,0.9
                                c-0.2,0.2-0.3,0.4-0.4,0.5c-0.1,0-0.7,0.3-1.3,0c-0.9-0.4-1.7-0.9-3-2.3c-1.4-1.4-1.9-2.2-2.3-3.1c-0.3-0.7,0-1.3,0-1.3
                                C8.4,10,8.6,9.8,8.8,9.7c0.3-0.2,0.6-0.4,0.9-0.8c0.9-1,0.7-2.4,0.4-3.1c-0.6-1-1.5-2.4-2.5-3.8L7.1,1.2C6.6,0.4,5.8,0,5,0
                                C4.4,0,4,0.2,3.7,0.3C3,0.6,1.6,1.6,0.5,3.6C-0.8,6.2,0,11.5,6.8,18.2C12.6,24.1,16.9,25,19,25c0.9,0,1.8-0.2,2.4-0.5
                                c2-1.1,3.1-2.5,3.3-3.1C25.3,20,25,18.7,23.8,17.9z M23.4,20.8c-0.1,0.3-0.9,1.5-2.7,2.4c-0.4,0.2-1,0.4-1.7,0.4
                                c-1.5,0-5.5-0.6-11.2-6.3C1.2,10.6,1,5.9,1.8,4.3c0.9-1.7,2.1-2.5,2.4-2.7C4.4,1.6,4.7,1.4,5,1.4h0h0h0c0.4,0,0.7,0.2,0.9,0.5
                                l0.5,0.8c1,1.4,1.9,2.8,2.4,3.7c0.1,0.2,0.3,0.9-0.2,1.4C8.4,8.2,8.1,8.4,7.9,8.5C7.6,8.7,7.3,9,7.1,9.3C6.7,9.9,6.5,10.9,7,12
                                c0.6,1.2,1.3,2.1,2.6,3.5c1.3,1.3,2.3,2,3.4,2.6c0.4,0.2,0.8,0.3,1.3,0.3c0.7,0,1.2-0.3,1.4-0.4c0.4-0.2,0.6-0.5,0.8-0.8
                                c0.2-0.2,0.3-0.5,0.6-0.7c0.6-0.6,1.3-0.3,1.4-0.2c0.9,0.5,2.3,1.4,3.7,2.4l0.8,0.6C23.6,19.5,23.7,20.1,23.4,20.8z"/>
                            <polygon points="19,25 19,25 19,25 	"/>
                        </g>
                    </svg>
                <span class="sr-only">{{DachziegelData.data.options.lang.general_contact}}</span>
            </router-link>
        </div>
        <div class="footer-info">
            <div class="container-fluid">
                <p>{{DachziegelData.data.options.settings.app_footer_copy_text}}</p>
                <router-link to="/contentImpressum">{{DachziegelData.data.options.lang.general_imprint}}</router-link>
                <p>v. {{DachziegelAppVersion}}#{{DachziegelDataVersion}}</p>
            </div>
        </div>
    </footer>
</template>
<script>
    import {DachziegelDataMixin} from "../mixins/DachziegelDataMixin";

    export default {
        name: 'AppFooter',
        mixins: [DachziegelDataMixin]
    }
</script>

