<template>
    <div>
        <!-- Header with navbar -->
        <header>
            <div class="navbar-main fixed-top">
                <div class="container-fluid">
                    <div class="navbar-toggle">
                      <button class="offside-button" type="button" id="my-button">
                        <svg class="icon" aria-hidden="true"><use xlink:href="#icon-burger"></use></svg>
                        <span class="visually-hidden">Hauptnavigation einblenden</span>
                      </button>
                    </div>
                    <router-link to="/" class="navbar-logo">
                        <img v-if="DachziegelData.data.options.current_lang === 'da'" src="../assets/images/logo-jacobi-walther-da.jpg" height="34" alt="Logo Jacobi Walther">
                        <img v-else src="../assets/images/logo-jacobi-walther.svg" width="90" height="34" alt="Logo Jacobi Walther">
                    </router-link>
                </div>
            </div>
        </header>
        <!-- Offcanvas Menu -->
        <div id="offside-menu" class="offside">
            <div class="offside__inner">
                <div class="close-offcanvas">
                    <button class="offcanvas-close-button">
                        <svg class="icon" aria-hidden="true"><use xlink:href="#icon-close"></use></svg>
                        {{DachziegelData.data.options.lang.nav_close}}
                    </button>
                </div>
                <nav class="main-navigation">
                    <ul class="main-menu">
                        <li class="main-menu__item">
                            <router-link to="/" class="main-menu__item__link">{{DachziegelData.data.options.lang.nav_home}}</router-link>
                        </li>
                        <li class="main-menu__item">
                            <router-link to="/ziegelliste" class="main-menu__item__link">{{DachziegelData.data.options.lang.nav_technology}}</router-link>
                        </li>
                        <li class="main-menu__item">
                            <a href="#" @click.prevent="openUrl(DachziegelData.data.options.lang.nav_order_url)"
                               class="main-menu__item__link"> {{DachziegelData.data.options.lang.nav_order}}
                            </a>
                        </li>
                        <li class="main-menu__item">
                            <router-link class="main-menu__item__link" to="/contentKontakt">{{DachziegelData.data.options.lang.general_contact}}</router-link>
                        </li>

                        <li class="main-menu__item">
                            <router-link to="/contentImpressum" class="main-menu__item__link">{{DachziegelData.data.options.lang.general_imprint}}</router-link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>
<script>
    import {DachziegelDataMixin} from "../mixins/DachziegelDataMixin";

    export default {
        name: 'AppHeader',
        mixins: [DachziegelDataMixin],
        mounted: function () {
            const offsideMenu1 = this.$offside('#offside-menu', {
                slidingElementsSelector: '#offside-container',
                buttonsSelector: '.offside-button, .offcanvas-close-button',
                slidingSide: 'left'
            });
            const overlay = document.querySelector('.main-navigation').addEventListener('click', this.$offside.factory.closeOpenOffside);
        },
        methods: {
          openUrl : function ($url) {
            window.open(encodeURI($url), '_system','location=yes');
          }
        }
    }
</script>
