import Vue from 'vue'

Vue.config.productionTip = false
import VueFriendlyIframe from 'vue-friendly-iframe';
import offside from 'offside-js';

Object.defineProperty(Vue.prototype, '$offside', {value: offside});
import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);
import loading from 'vue-loading-overlay';
import router from './router'
import store from './store'
import './registerServiceWorker'
import 'vue-loading-overlay/dist/vue-loading.css';
import './styles/styles.scss'
import init from './init.js'
import app from './App'

document.addEventListener("deviceready", onDeviceReady, false);
document.addEventListener("onappready", onAPPReady, false);


function onDeviceReady() {
    // Init plugin
    Vue.use(VueFriendlyIframe)
    Vue.use(loading)
    Vue.use(init)
    Vue.$DKFuctions.init();
}

function onAPPReady() {
    //APP
    new Vue({
        router,
        store,
        render: h => h(app)
    }).$mount('#app')
    navigator.splashscreen.hide()
}




