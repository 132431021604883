import Axios from "axios";
import store from './store';
import * as ImgCache from "@chrisben/imgcache.js";

let languages = {
  DE: 'de',
  DA: 'da'
};
/**
 * Project Settings
 * @type {{localStorageConfgKey: string, appLanguage: string, adminAjaxVersion: string, adminAjax: string}}
 */
let defaultOptions = {
    'APP_VERSION': '1.2.0',
    'appLanguage': languages.DE,
    'localStorageConfgKey': 'dkConfig',
    'localStorageDownloadKey': 'dkConfigDL',
    'adminAjax': 'https://www.dachziegel.de/wp-admin/admin-ajax.php?action=cl_webapp&lang=',
    'adminAjaxVersion': 'https://www.dachziegel.de/wp-admin/admin-ajax.php?action=cl_webapp_v&lang=',
};
export default {
    install: function (Vue, options) {
        defaultOptions = {...defaultOptions, ...options};
        console.log('DK Init Plugins...');
        //ImgCache
        ImgCache.options.debug = false;
        // increase allocated space on Chrome to 50MB, default was 10MB
        ImgCache.options.chromeQuota = 50 * 1024 * 1024;
        // Instead of using the PERSISTENT or TEMPORARY filesystems, use one of the
        ImgCache.options.cordovaFilesystemRoot = cordova.file.dataDirectory;
        ImgCache.init(function () {
            console.log('ImgCache.init');
        }, function () {
            console.log('Keine Schreibrechte auf dem System');
        });
        //Init App
        let loader;
        var api = {
            /**
             * Init
             */
            init: async function () {
                console.log('Function: init');
                window.open = cordova.InAppBrowser.open;
                store.commit('updateField', {path: 'DachziegelAppVersion', value: defaultOptions.APP_VERSION});
                store.commit('updateField', {path: 'renderComponent', value: false});
                store.commit('updateField', {path: 'networkState', value: navigator.connection.type});
                if (window.cordova) {
                    store.commit('updateField', {path: 'isCordovaApp', value: true})
                }
                if (localStorage.getItem(defaultOptions.localStorageConfgKey)) {
                    console.log('Load _loadApiData - Cache');
                    let _DachziegelData = JSON.parse(localStorage.getItem(defaultOptions.localStorageConfgKey));
                    if (navigator.onLine) {
                        await Vue.$DKFuctions._loadApiVersion();
                        console.log('Load _loadApiData - Check DB-Version', store.state.DachziegelDataVersion, _DachziegelData.data.options.settings.app_version);
                        if (Number(_DachziegelData.data.options.settings.app_version) < Number(store.state.DachziegelDataVersion)) {
                            console.log('Load _loadApiData - Check DB-Version - NEW DB VERSION');
                            localStorage.removeItem(defaultOptions.localStorageConfgKey);
                            localStorage.removeItem(defaultOptions.localStorageDownloadKey);
                            await Vue.$DKFuctions._loadApiData()
                        }
                    }
                    store.commit('updateField', {
                        path: 'DachziegelDataVersion',
                        value: _DachziegelData.data.options.settings.app_version
                    });
                    store.commit('updateField', {path: 'DachziegelData', value: _DachziegelData})
                    await Vue.$DKFuctions._imageDownloadCheck()
                } else {
                    await Vue.$DKFuctions._loadApiData()
                }
                console.log('Function: init - DONE')
                var event = new CustomEvent("onappready", {});
                document.dispatchEvent(event);
            },
            /**
             * Preload Images
             */
          preLoadImages: function () {
                console.log('preLoadImages: Start')
                Vue.$DKFuctions.loaderShow()
                Object.keys(store.state.DachziegelData.data.imgs.img).forEach(function (k) {
                    Vue.$DKFuctions._cacheImg(store.state.DachziegelData.data.imgs.img[k].url)
                })
                localStorage.setItem(defaultOptions.localStorageDownloadKey, store.state.DachziegelDataVersion);
                store.commit('updateField', {
                    path: 'imageDownloads', value: true
                });
                store.commit('updateField', {
                    path: 'imageDownloadsVersion', value: store.state.DachziegelDataVersion
                })
                store.commit('updateField', {
                    path: 'renderComponent', value: true
                })
                Vue.$DKFuctions.loaderHide()
            },
            loaderShow: function () {
                loader = Vue.$loading.show({
                    container: null,
                    canCancel: true, // default false
                    color: '#e3051b',
                    loader: 'spinner',
                    width: 90,
                    height: 90,
                    backgroundColor: '#ffffff',
                    opacity: 0.9,
                    blur: '10px',
                    zIndex: 999,
                });
            },
            loaderHide: function () {
                loader.hide()
            },
            headerShowGoBackHide: function () {
                store.commit('updateField', {path: 'headerShowGoBack', value: false});
            },
            headerShowGoBackShow: function () {
                store.commit('updateField', {
                    path: 'headerShowGoBack', value: true
                });
            },
            /**
             * Skip DL Screen
             */
            preLoadImagesSkip: function () {
                store.commit('updateField', {path: 'renderComponent', value: true});
            },
            /**
             * getImgFromID
             * @param imgID
             * @returns {boolean|*}
             */
            getImgFromID: function (imgID) {
                if (store.state.DachziegelData.data.imgs.img.hasOwnProperty(imgID)) {
                    return store.state.DachziegelData.data.imgs.img[imgID]
                } else {
                    return false
                }
            },
            getZiegelFromID: function (ziegelID) {
                if (store.state.DachziegelData.data.ziegel.hasOwnProperty(ziegelID)) {
                    return store.state.DachziegelData.data.ziegel[ziegelID]
                } else {
                    return false
                }
            },
            /**
             * findZiegelTermFromTaxonomy
             * @param ziegelID
             * @param taxonomy
             * @returns {boolean|*}
             */

            findZiegelTermFromTaxonomy(ziegelID,taxonomy) {
                if (store.state.DachziegelData.data.ziegel.hasOwnProperty(ziegelID)) {
                    return store.state.DachziegelData.data.ziegel[ziegelID].all_terms.find(all_terms => {
                        if (all_terms.taxonomy === taxonomy) {
                            return all_terms
                        }
                    })
                } else {
                    return false
                }
            },
            _cacheImg: function (src) {
                ImgCache.isCached(src, function (path, success) {
                    if (success) {
                        // already cached
                        //ImgCache.useCachedFile(target);
                        console.log('_cacheImg: already cached');
                    } else {
                        // not there, need to cache the image
                        console.log('_cacheImg: need to cache the image');
                        ImgCache.cacheFile(src, function () {
                            //ImgCache.useCachedFile(target);
                        });
                    }
                });
            },
            /**
             * Load data vom DK API
             * @private
             */
            _loadApiData: async function () {
                console.log('Function: _loadApiData');
                // Offline
                if (!navigator.onLine) {
                    console.log('Load _loadApiData - Offline');
                    const parsed = JSON.stringify(Vue.$DKFuctions._requireWebappJson());
                    localStorage.setItem(defaultOptions.localStorageConfgKey, parsed);
                    store.commit('updateField', {
                        path: 'DachziegelData', value: parsed
                    })
                } else {
                    // Online
                    console.log('Load _loadApiData - Online');
                    return Axios.get(Vue.$DKFuctions._getAdminAjaxUrl())
                        .then((response) => {
                            console.log('Load _loadApiData - Online - ajax call');
                            const parsed = JSON.stringify(response.data);
                            localStorage.setItem(defaultOptions.localStorageConfgKey, parsed);
                            store.commit('updateField', {path: 'DachziegelData', value: response.data})
                            store.commit('updateField', {
                                path: 'DachziegelDataVersion',
                                value: response.data.data.options.settings.app_version
                            });
                            Vue.$DKFuctions._imageDownloadCheck()
                        })
                }
            },
            _imageDownloadCheck: function () {
                console.log('Function: _imageDownloadCheck');
                if (localStorage.getItem(defaultOptions.localStorageDownloadKey)) {
                    Vue.$DKFuctions._loadApiVersion();
                    if (localStorage.getItem(defaultOptions.localStorageDownloadKey) < Number(store.state.DachziegelDataVersion)) {
                        console.log('Load _imageDownloadCheck - Check IMG-Version - NEW IMG VERSION');
                        localStorage.removeItem(defaultOptions.localStorageDownloadKey);
                        store.commit('updateField', {
                            path: 'imageDownloads', value: false
                        });
                        store.commit('updateField', {
                            path: 'imageDownloadsVersion', value: false
                        })
                        ImgCache.clearCache();
                    } else {
                        store.commit('updateField', {
                            path: 'renderComponent', value: true
                        });
                        store.commit('updateField', {
                            path: 'imageDownloads', value: true
                        });
                        store.commit('updateField', {
                            path: 'imageDownloadsVersion',
                            value: localStorage.getItem(defaultOptions.localStorageDownloadKey)
                        })
                    }
                } else {
                    store.commit('updateField', {
                        path: 'imageDownloads', value: false
                    });
                    store.commit('updateField', {
                        path: 'imageDownloadsVersion', value: false
                    })
                }
                //Check Images
                Object.keys(store.state.DachziegelData.data.imgs.img).forEach(function (k) {
                    ImgCache.isCached(store.state.DachziegelData.data.imgs.img[k].url, function (path, success) {
                      if (success) {

                            ImgCache.getCachedFileURL(path, function (a, b) {
                                console.log('Function: getCachedFileURL', b, store.state.DachziegelData.data.imgs.img[k].url);
                                let tmp = store.state.DachziegelData;
                                if (Vue.$DKFuctions.getOS() === 'Windows') {
                                   tmp.data.imgs.img[k].url = store.state.DachziegelData.data.imgs.img[k].url;
                                } else if (Vue.$DKFuctions.getOS() === 'iOS') {

                                   tmp.data.imgs.img[k].url = store.state.DachziegelData.data.imgs.img[k].url;
                              }  else {
                                    tmp.data.imgs.img[k].url = b;
                                }
                                store.commit('updateField', {path: 'DachziegelData', value: tmp})
                            })
                        }
                    });
                })
            },
            getOS: function () {
                var userAgent = window.navigator.userAgent,
                    platform = window.navigator.platform,
                    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
                    os = null;
                if (macosPlatforms.indexOf(platform) !== -1) {
                    os = 'Mac OS';
                } else if (iosPlatforms.indexOf(platform) !== -1) {
                    os = 'iOS';
                } else if (windowsPlatforms.indexOf(platform) !== -1) {
                    os = 'Windows';
                } else if (/Android/.test(userAgent)) {
                    os = 'Android';
                } else if (!os && /Linux/.test(platform)) {
                    os = 'Linux';
                }
                return os;
            },
            /**
             * Get APP Version from API
             * @returns {*}
             * @private
             */
            _loadApiVersion: async function () {
                console.log('Function: _loadApiVersion');

                if (!navigator.onLine) {
                    console.log('Function: _loadApiVersion OFFLINE');
                }

                let response = await Axios.get(Vue.$DKFuctions._getAdminAjaxVersionUrl());
                store.commit('updateField', {
                    path: 'DachziegelDataVersion',
                    value: response.data.data.options.settings.app_version
                });
                console.log('Function: _loadApiVersion - done', response.data.data.options.settings.app_version)
            },
            /**
             * Get the correct adminAjaxVersion url depending on the hard coded language.
             */
            _getAdminAjaxVersionUrl: function() {
              return defaultOptions.adminAjaxVersion + defaultOptions.appLanguage;
            },
            /**
             * Get the correct adminAjax url depending on the hard coded language.
             */
            _getAdminAjaxUrl: function() {
              return defaultOptions.adminAjax + defaultOptions.appLanguage;
            },
            /**
             * Get the correct adminAjaxVersion url depending on the hard coded language.
             */
            _requireWebappJson: function() {
              if (defaultOptions.appLanguage === 'da') {
                return require('./json/cl_webapp_da');
              }
              return require('./json/cl_webapp');
            }
        };
        Vue.$DKFuctions = api;
        Vue.prototype.$DKFuctions = api;
    }
}
