import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '*',
        redirect: '/'
    },
    {
        path: '/index.html',
        name: 'Home Index',
        component: Home,
    },
    {
        path: '/android_asset/www/index.html',
        name: 'Home Index',
        component: Home,
    },
    {
        path: '/iframe',
        name: 'iframe-View',
        component: () => import('../views/iframeView.vue'),
        props: {iframe: false},
    },
    {
        path: '/contentImpressum',
        name: 'contentImpressum-View',
        component: () => import('../views/contentImpressum.vue'),
    },
    {
        path: '/contentKontakt',
        name: 'contentKontakt-View',
        component: () => import('../views/contentKontakt.vue'),
    },
    {
        path: '/ziegelliste/:ziegelID',
        name: 'ZiegelDetail',
        component: () => import('../views/ZiegelDetail.vue')
        ,
    },
    {
        path: '/ziegelliste',
        name: 'ZiegelListe',
        component: () => import('../views/ZiegelListe.vue')
    },
    {
        path: '/status852',
        name: 'Status',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Status')
    }
]
const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes
})
export default router
