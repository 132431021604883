<template>
    <div class="page" id="app">
        <!-- Site Overlay -->
        <div class="site-overlay"></div>

        <AppViewLoader v-show="!renderComponent"/>
        <AppViewContent v-show="renderComponent"/>

    <svg aria-hidden="true" style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="icon-burger" viewBox="0 0 32 32">
          <path d="M1.473 3.652c-0.006-0-0.013-0-0.021-0-0.802 0-1.453 0.65-1.453 1.453s0.65 1.453 1.453 1.453c0.007 0 0.014-0 0.022-0h29.052c0.006 0 0.013 0 0.021 0 0.802 0 1.453-0.65 1.453-1.453s-0.65-1.453-1.453-1.453c-0.007 0-0.014 0-0.022 0h-29.052zM1.473 14.547c-0.006-0-0.013-0-0.021-0-0.802 0-1.453 0.65-1.453 1.453s0.65 1.453 1.453 1.453c0.007 0 0.014-0 0.022-0h29.052c0.006 0 0.013 0 0.021 0 0.802 0 1.453-0.65 1.453-1.453s-0.65-1.453-1.453-1.453c-0.007 0-0.014 0-0.022 0h-29.052zM1.473 25.442c-0.006-0-0.013-0-0.021-0-0.802 0-1.453 0.65-1.453 1.453s0.65 1.453 1.453 1.453c0.007 0 0.014-0 0.022-0h29.052c0.006 0 0.013 0 0.021 0 0.802 0 1.453-0.65 1.453-1.453s-0.65-1.453-1.453-1.453c-0.007 0-0.014 0-0.022 0h-29.052z"></path>
        </symbol>
        <symbol id="icon-chevron-top" viewBox="0 0 32 32">
          <path d="M14.328 8.163l-14.328 14.328 3.343 3.343 12.657-12.657 12.657 12.657 3.343-3.343-14.328-14.328c-0.955-0.955-2.388-0.955-3.343 0z"></path>
        </symbol>
        <symbol id="icon-chevron-right" viewBox="0 0 32 32">
          <path d="M24.478 14.328l-14.328-14.328-3.343 3.343 12.657 12.657-12.657 12.657 3.343 3.343 14.328-14.328c0.955-0.955 0.955-2.388 0-3.343z"></path>
        </symbol>
        <symbol id="icon-chevron-down" viewBox="0 0 32 32">
          <path d="M17.672 24.478l14.328-14.328-3.343-3.343-12.657 12.657-12.657-12.657-3.343 3.343 14.328 14.328c0.955 0.955 2.388 0.955 3.343 0z"></path>
        </symbol>
        <symbol id="icon-chevron-left" viewBox="0 0 32 32">
          <path d="M7.522 17.672l14.328 14.328 3.343-3.343-12.657-12.657 12.657-12.657-3.343-3.343-14.328 14.328c-0.955 0.955-0.955 2.388 0 3.343z"></path>
        </symbol>
        <symbol id="icon-caret" viewBox="0 0 30 32">
          <path d="M21.558 12.632l-6.4 6.4-6.4-6.4-2.358 2.526 7.579 7.579c0.337 0.337 0.842 0.505 1.179 0.505s0.842-0.168 1.179-0.505l7.579-7.579-2.358-2.526z"></path>
        </symbol>
        <symbol id="icon-caret-dropdown" viewBox="0 0 32 32">
          <path d="M9.841 13.958l5.467 5.467c0.383 0.383 1.002 0.383 1.384 0l5.467-5.467c0.617-0.617 0.18-1.671-0.692-1.671h-10.934c-0.872 0-1.309 1.054-0.692 1.671z"></path>
        </symbol>
        <symbol id="icon-close" viewBox="0 0 32 32">
          <path d="M32 2.667l-2.667-2.667-13.333 13.333-13.333-13.333-2.667 2.667 13.333 13.333-13.333 13.333 2.667 2.667 13.333-13.333 13.333 13.333 2.667-2.667-13.333-13.333z"></path>
        </symbol>
        <symbol id="icon-dice" viewBox="0 0 32 32">
          <path d="M16 0l-14.664 5.346v18.673l0.649 0.344 14.014 7.637 0.573-0.305 14.091-7.675v-18.673l-0.802-0.306-13.862-5.041zM16 2.597l10.234 3.704-10.234 4.659-10.234-4.659 10.234-3.704zM16 4.964c-1.351 0-2.444 0.549-2.444 1.222s1.093 1.222 2.444 1.222c1.351 0 2.444-0.549 2.444-1.222s-1.093-1.222-2.444-1.222zM3.78 8.057l10.998 5.002v15.504l-10.998-5.995v-14.511zM28.22 8.057v14.511l-10.998 5.995v-15.504l10.998-5.002zM11.914 13.518c-0.453 0-0.802 0.434-0.802 1.069 0 0.816 0.434 1.661 1.069 2.024 0.181 0.091 0.391 0.076 0.573 0.076 0.272 0 0.43-0.086 0.611-0.267 0.091-0.181 0.191-0.439 0.191-0.802 0-0.816-0.549-1.661-1.184-2.024-0.181-0.091-0.277-0.076-0.458-0.076zM20.926 14.74c-0.091 0.014-0.2 0.062-0.305 0.115-0.745 0.32-1.375 1.375-1.375 2.329 0 0.32-0.024 0.625 0.191 0.84s0.444 0.344 0.764 0.344c0.215 0 0.434-0.010 0.649-0.115 0.745-0.425 1.26-1.403 1.26-2.253 0-0.745-0.315-1.26-0.955-1.26-0.053 0-0.138-0.014-0.229 0zM8.821 16.305c-0.453 0-0.802 0.434-0.802 1.069 0 0.816 0.434 1.623 1.069 1.986 0.181 0.091 0.353 0.115 0.535 0.115 0.272 0 0.468-0.086 0.649-0.267 0.091-0.181 0.191-0.477 0.191-0.84 0-0.816-0.549-1.623-1.184-1.986-0.181-0.091-0.277-0.076-0.458-0.076zM25.203 17.718c-0.091 0.014-0.2 0.024-0.305 0.076-0.745 0.32-1.375 1.413-1.375 2.368 0 0.32 0.014 0.625 0.229 0.84s0.406 0.305 0.725 0.305c0.215 0 0.434 0.029 0.649-0.076 0.745-0.425 1.26-1.403 1.26-2.253 0-0.745-0.315-1.26-0.955-1.26-0.053 0-0.138-0.014-0.229 0zM5.804 18.902c-0.453 0-0.802 0.434-0.802 1.069 0 0.816 0.434 1.661 1.069 2.024 0.181 0.091 0.391 0.076 0.573 0.076 0.272 0 0.43-0.086 0.611-0.267 0.091-0.181 0.191-0.439 0.191-0.802 0-0.816-0.549-1.661-1.184-2.024-0.181-0.091-0.277-0.076-0.458-0.076z"></path>
        </symbol>
        <symbol id="icon-roof-slope" viewBox="0 0 32 32">
          <path d="M31.197 31.998h-30.397c-0.48 0-0.8-0.32-0.8-0.8s0.32-0.8 0.8-0.8h28.477l-29.037-29.037c-0.32-0.32-0.32-0.8 0-1.12s0.8-0.32 1.12 0l30.397 30.397c0.24 0.24 0.32 0.56 0.16 0.88s-0.4 0.48-0.72 0.48z"></path>
          <path d="M31.197 31.838c-0.080 0-0.080 0-0.16 0l-30.397-6.399c-0.32-0.080-0.56-0.4-0.48-0.72s0.4-0.56 0.72-0.48l30.397 6.399c0.32 0.080 0.56 0.4 0.48 0.72-0.080 0.24-0.32 0.48-0.56 0.48z"></path>
          <path d="M31.197 31.838c-0.080 0-0.16 0-0.24-0.080l-30.397-14.399c-0.32-0.16-0.4-0.48-0.32-0.8 0.16-0.32 0.48-0.4 0.8-0.32l30.397 14.399c0.32 0.16 0.4 0.48 0.32 0.8-0.16 0.24-0.4 0.4-0.56 0.4z"></path>
          <path d="M31.197 31.838c-0.16 0-0.24 0-0.32-0.080l-30.397-22.398c-0.24-0.16-0.32-0.56-0.16-0.8s0.56-0.32 0.8-0.16l30.397 22.398c0.24 0.16 0.32 0.56 0.16 0.8-0.16 0.080-0.32 0.24-0.48 0.24z"></path>
        </symbol>
        <symbol id="icon-sparkle" viewBox="0 0 32 32">
          <path d="M20.667 6.667l-3.333 8-8 3.333 8 3.333 3.333 8 3.333-8 8-3.333-8-3.333-3.333-8zM5.5 10.5l1.833 5.5 1.833-5.5 5.5-1.833-5.5-1.833-1.833-5.5-1.833 5.5-5.5 1.833zM8.5 24.833l-1.167-3.5-1.167 3.5-3.5 1.167 3.5 1.167 1.167 3.5 1.167-3.5 3.5-1.167z"></path>
        </symbol>
        <symbol id="icon-quantity" viewBox="0 0 32 32">
          <path d="M21.818 2.182h-21.818v10.182c0 2.982 2.255 5.382 5.091 5.745v5.891c0 3.2 2.618 5.818 5.818 5.818 2.182 0 4.073-1.236 5.091-2.982 1.018 1.818 2.909 2.982 5.091 2.982 3.2 0 5.818-2.618 5.818-5.818v-5.891c2.836-0.364 5.091-2.764 5.091-5.745v-10.182h-10.182zM30.545 3.636v2.327h-8.727v-2.327h8.727zM16 16.727c-2.4 0-4.364-1.964-4.364-4.364v-5.309h8.727v5.309c0 2.4-1.964 4.364-4.364 4.364zM20.364 3.636v2.327h-8.727v-2.327h8.727zM10.182 3.636v2.327h-8.727v-2.327h8.727zM1.455 12.364v-5.309h8.727v5.309c0 2.4-1.964 4.364-4.364 4.364s-4.364-1.964-4.364-4.364zM10.909 28.364c-2.4 0-4.364-1.964-4.364-4.364v-5.891c1.818-0.218 3.418-1.309 4.291-2.836h0.145c0.873 1.527 2.473 2.618 4.291 2.836v5.891c0 2.4-1.964 4.364-4.364 4.364zM21.091 28.364c-2.4 0-4.364-1.964-4.364-4.364v-5.891c1.818-0.218 3.418-1.309 4.291-2.836h0.145c0.873 1.527 2.473 2.618 4.291 2.836v5.891c0 2.4-1.964 4.364-4.364 4.364zM26.182 16.727c-2.4 0-4.364-1.964-4.364-4.364v-5.309h8.727v5.309c0 2.4-1.964 4.364-4.364 4.364z"></path>
        </symbol>
        <symbol id="icon-cover-width" viewBox="0 0 32 32">
          <path d="M10.629 0v10.667h-6.095v12.952c0 4.648 3.733 8.381 8.381 8.381s8.381-3.733 8.381-8.381v-2.667c1.752-0.457 3.276-1.524 4.343-2.895 0.229-0.305 0.381-0.533 0.61-0.838 0.762-1.219 1.219-2.743 1.219-4.267v-12.952h-16.838zM25.867 1.524v2.438h-13.714v-2.438h13.714zM6.057 12.19h4.571v0.762c0 0.305 0 0.61 0.076 0.914 0 0.229 0.076 0.533 0.152 0.762h-4.724l-0.076-2.438zM19.771 23.619c0 3.733-3.048 6.857-6.857 6.857-3.733 0-6.857-3.048-6.857-6.857v-7.848h5.105c0.152 0.381 0.305 0.762 0.533 1.143s0.457 0.762 0.762 1.143c0.305 0.457 0.686 0.838 1.143 1.219 0 0 0 0 0 0 0.152 0.152 0.305 0.305 0.533 0.381 0.076 0.076 0.152 0.076 0.229 0.152s0.229 0.152 0.305 0.152c0.152 0.076 0.305 0.229 0.457 0.305 0 0 0 0 0 0 1.219 0.686 2.514 0.99 3.962 0.99h0.762l-0.076 2.362zM19.009 19.81c-1.143 0-2.286-0.305-3.2-0.838 0 0-0.076 0-0.076 0-0.076-0.076-0.229-0.152-0.305-0.229s-0.229-0.152-0.305-0.152c-0.076 0-0.076-0.076-0.152-0.076-0.152-0.152-0.381-0.305-0.533-0.381h6.781v-1.143h-7.771c-0.457-0.61-0.762-1.219-0.99-1.905h8.762v-1.143h-8.99c0-0.076 0-0.229-0.076-0.305 0-0.229 0-0.457 0-0.61v-0.99h9.143v-1.143h-9.143v-5.638h13.714v7.848c0 1.448-0.457 2.895-1.295 3.962-0.305 0.457-0.686 0.838-1.067 1.143-1.219 0.914-2.819 1.6-4.495 1.6z"></path>
        </symbol>
        <symbol id="icon-cover-length" viewBox="0 0 32 32">
          <path d="M10.667 0v10.667h-6.095v12.952c0 4.648 3.733 8.381 8.381 8.381 4.571 0 8.381-3.733 8.381-8.381v-2.667c3.505-0.99 6.095-4.19 6.095-8v-12.952h-16.762zM25.905 1.524v2.438h-13.714v-2.438h13.714zM10.667 12.19v0.762c0 0 0 0 0 0 0 0.229 0 0.533 0.076 0.762 0 0.305 0.076 0.61 0.152 0.914h-4.8v-2.438h4.571zM19.809 23.619c0 3.733-3.048 6.857-6.857 6.857-3.733 0-6.857-3.048-6.857-6.857v-7.848h5.105c0.457 1.371 1.295 2.514 2.362 3.505 0 0 0 0 0 0 0.076 0.076 0.229 0.152 0.305 0.229v5.181h1.143v-4.419c0 0 0 0 0 0s0 0 0 0c0.61 0.305 1.219 0.533 1.829 0.762v2.438h1.143v-2.21c0.305 0 0.61 0.076 0.914 0.076h0.762l0.152 2.286zM21.181 19.505v-11.886h-1.143v12.114c-0.381 0.076-0.686 0.076-0.99 0.076s-0.61 0-0.914-0.076v-11.505h-1.143v11.276c-0.381-0.152-0.762-0.305-1.067-0.457 0 0-0.076 0-0.076 0-0.076-0.076-0.229-0.152-0.305-0.152-0.076-0.076-0.229-0.152-0.305-0.152s-0.076-0.076-0.076-0.076c0 0 0 0 0 0v-9.219h-1.143v8c-0.914-1.067-1.524-2.438-1.676-3.81 0-0.229 0-0.457 0-0.61v-7.848h13.714l-0.152 7.771c0 3.048-1.981 5.638-4.724 6.552z"></path>
        </symbol>
        <symbol id="icon-total-dimension" viewBox="0 0 32 32">
          <path d="M3.194 0.624v15.877c0 5.444 4.385 9.829 9.829 9.829s9.829-4.385 9.829-9.829v-15.877h-19.657zM21.34 2.136v3.175h-16.633v-3.175h16.633zM13.023 24.817c-4.536 0-8.317-3.705-8.317-8.317v-10.056h16.633v10.056c0 4.612-3.78 8.317-8.317 8.317zM24.742 28.976l-3.024 3.024-0.832-0.832 2.041-2.041h-19.884l2.041 2.041-0.832 0.832-3.024-3.024c-0.227-0.227-0.227-0.605 0-0.832l3.024-3.024 0.832 0.832-2.041 2.041h19.96l-2.041-2.041 0.832-0.832 3.024 3.024c0.151 0.302 0.151 0.605-0.076 0.832zM30.034 22.927l0.832 0.832-3.024 3.024c-0.076 0.076-0.227 0.151-0.378 0.151s-0.302-0.076-0.378-0.151l-3.024-3.024 0.832-0.832 2.041 2.041v-22.984l-2.041 2.041-0.832-0.832 3.024-3.024c0.227-0.227 0.605-0.227 0.832 0l3.024 3.024-0.832 0.832-2.041-2.041v22.984l1.966-2.041z"></path>
        </symbol>
        <symbol id="icon-weight" viewBox="0 0 32 32">
          <path d="M28.755 13.358c-0.528-2.868-3.019-4.906-5.962-4.906h-3.472c0.755-0.83 1.132-1.887 1.132-3.019 0-2.491-2.038-4.528-4.528-4.528s-4.528 2.038-4.528 4.528c0 1.132 0.453 2.189 1.132 3.019h-3.321c-2.943 0-5.434 2.038-5.962 4.906l-3.245 17.736h32l-3.245-17.736zM12.981 5.434c0-1.66 1.358-3.019 3.019-3.019s3.019 1.358 3.019 3.019-1.358 3.019-3.019 3.019c-1.66 0-3.019-1.359-3.019-3.019zM4.83 13.66c0.377-2.189 2.189-3.698 4.377-3.698h13.585c2.189 0 4.075 1.509 4.453 3.698l2.943 15.925h-28.377l3.019-15.925z"></path>
          <path d="M20.83 18.642c-0.302-0.604-1.057-0.981-1.887-0.981-1.66 0-2.792 1.358-2.792 3.17 0 1.887 1.208 3.245 2.792 3.245 0.679 0 1.434-0.302 1.887-0.981v0.906c0 0.981-0.604 1.358-1.434 1.358-0.755 0-1.208-0.226-1.358-0.83h-1.585c0.226 1.509 1.434 2.038 2.868 2.038 1.962 0 3.17-0.83 3.17-2.943v-5.811h-1.66v0.83zM19.396 22.793c-0.83 0-1.509-0.83-1.509-1.962 0-1.057 0.604-1.962 1.509-1.962s1.509 0.83 1.509 1.962c0 1.208-0.604 1.962-1.509 1.962z"></path>
          <path d="M15.396 17.811h-1.887l-2.34 2.566v-4.83h-1.66v8.755h1.66v-1.962l0.83-0.83 1.66 2.792h1.887l-2.491-4z"></path>
        </symbol>
        <symbol id="icon-arrow-link--small" viewBox="0 0 32 32">
          <path d="M21.785 7.985h-12.8v2.462h9.846l-9.6 9.6 1.723 1.723 9.6-9.6v9.846h2.462v-12.8c0-0.738-0.492-1.231-1.231-1.231z"></path>
        </symbol>
        <symbol id="icon-arrow-link" viewBox="0 0 32 32">
          <path d="M29.193 0h-29.193v5.614h22.456l-21.895 21.895 3.93 3.93 21.895-21.895v22.456h5.614v-29.193c0-1.684-1.123-2.807-2.807-2.807z"></path>
        </symbol>
        <symbol id="icon-parcel" viewBox="0 0 32 32">
          <path d="M31.88 9.752l-4.005-8.010c-0.32-0.641-0.801-0.961-1.442-0.961h-20.826c-0.641 0-1.121 0.32-1.442 0.961l-4.005 8.010c-0.16 0.16-0.16 0.641-0.16 0.961l2.403 13.777c0.641 3.845 3.845 6.728 7.85 6.728h11.534c4.005 0 7.209-2.884 7.85-6.728l2.243-13.937c0.16-0.16 0.16-0.641 0-0.801zM28.195 9.592h-11.054v-5.607h8.17l2.884 5.607zM6.568 3.985h8.17v5.607h-10.894l2.723-5.607zM26.593 24.010c-0.32 2.403-2.403 4.005-4.806 4.005h-11.534c-2.403 0-4.325-1.762-4.806-4.005l-1.922-11.214h24.831l-1.762 11.214z"></path>
        </symbol>
        <symbol id="icon-youtube" viewBox="0 0 32 32">
          <path d="M16 0c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zM26.5 21.4c-0.1 0.5-0.4 0.9-0.7 1.2s-0.8 0.6-1.2 0.7c-1.7 0.5-8.6 0.5-8.6 0.5s-6.9 0-8.6-0.5c-0.5-0.1-0.9-0.4-1.2-0.7s-0.6-0.8-0.7-1.2c-0.5-1.7-0.5-5.3-0.5-5.3s0-3.6 0.5-5.3c0.1-0.5 0.4-0.9 0.7-1.2s0.8-0.6 1.2-0.7c1.7-0.6 8.6-0.6 8.6-0.6s6.9 0 8.6 0.5c0.5 0.1 0.9 0.4 1.2 0.7s0.6 0.8 0.7 1.2c0.5 1.7 0.5 5.3 0.5 5.3s0 3.7-0.5 5.4zM13.8 12.8l5.8 3.3-5.8 3.3v-6.6z"></path>
        </symbol>
        <symbol id="icon-expand" viewBox="0 0 32 32">
          <path d="M0 0v12h2.667v-7.417l9.708 9.708 1.917-1.917-9.708-9.708h7.417v-2.667zM20 0v2.667h7.417l-9.708 9.708 1.917 1.917 9.708-9.708v7.417h2.667v-12zM12.375 17.708l-9.708 9.708v-7.417h-2.667v12h12v-2.667h-7.417l9.708-9.708zM19.625 17.708l-1.917 1.917 9.708 9.708h-7.417v2.667h12v-12h-2.667v7.417z"></path>
        </symbol>
        <symbol id="icon-instagram" viewBox="0 0 32 32">
          <path d="M19.9 8.7h-7.9c-1.9 0-3.4 1.5-3.4 3.4v7.9c0 1.9 1.5 3.4 3.4 3.4h7.9c1.9 0 3.4-1.5 3.4-3.4v-7.9c0-1.9-1.5-3.4-3.4-3.4zM16 21.1c-2.8 0-5.1-2.3-5.1-5.1s2.3-5.1 5.1-5.1 5.1 2.3 5.1 5.1c0 2.8-2.3 5.1-5.1 5.1zM20.8 12.4c-0.6 0-1.1-0.5-1.1-1.1s0.5-1.1 1.1-1.1c0.6 0 1.1 0.5 1.1 1.1s-0.5 1.1-1.1 1.1z"></path>
          <path d="M16 0c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16-7.2-16-16-16zM25 20c0 2.8-2.3 5.1-5.1 5.1h-7.9c-2.8 0-5.1-2.3-5.1-5.1v-7.9c0-2.8 2.3-5.1 5.1-5.1h7.9c2.8 0 5.1 2.3 5.1 5.1v7.9z"></path>
          <path d="M19.4 16c0 1.878-1.522 3.4-3.4 3.4s-3.4-1.522-3.4-3.4c0-1.878 1.522-3.4 3.4-3.4s3.4 1.522 3.4 3.4z"></path>
        </symbol>
        <symbol id="icon-linkedin" viewBox="0 0 32 32">
          <path d="M16 0c-8.822 0-16 7.177-16 16s7.178 16 16 16 16-7.177 16-16-7.178-16-16-16zM11.13 22.957h-2.783v-10.435h2.783v10.435zM9.739 10.435c-0.769 0-1.391-0.623-1.391-1.391s0.623-1.391 1.391-1.391 1.391 0.623 1.391 1.391c0 0.769-0.623 1.391-1.391 1.391zM24.348 22.957h-2.783v-5.217c0-1.339-1.096-2.435-2.435-2.435s-2.435 1.096-2.435 2.435v5.217h-2.783v-10.435h2.783v1.263c0.813-0.781 1.914-1.263 3.13-1.263 2.497 0 4.522 2.024 4.522 4.522v5.913z"></path>
        </symbol>
        <symbol id="icon-pinterest" viewBox="0 0 32 32">
          <path d="M16.013 0c-8.809 0-15.95 7.158-15.95 15.987 0 6.776 4.201 12.566 10.134 14.895-0.144-1.263-0.263-3.21 0.052-4.592 0.289-1.25 1.864-7.947 1.864-7.947s-0.473-0.961-0.473-2.368c0-2.224 1.287-3.882 2.888-3.882 1.365 0 2.022 1.026 2.022 2.25 0 1.368-0.866 3.421-1.326 5.329-0.381 1.592 0.801 2.895 2.363 2.895 2.836 0 5.015-3 5.015-7.316 0-3.829-2.744-6.5-6.669-6.5-4.542 0-7.207 3.408-7.207 6.934 0 1.368 0.525 2.842 1.181 3.645 0.131 0.158 0.144 0.303 0.105 0.461-0.118 0.5-0.394 1.592-0.446 1.816-0.066 0.29-0.236 0.355-0.538 0.211-1.995-0.934-3.242-3.842-3.242-6.197 0-5.039 3.649-9.671 10.541-9.671 5.527 0 9.833 3.947 9.833 9.237 0 5.513-3.466 9.947-8.27 9.947-1.615 0-3.137-0.842-3.649-1.842 0 0-0.801 3.053-0.998 3.803-0.354 1.395-1.326 3.132-1.982 4.197 1.497 0.461 3.072 0.71 4.726 0.71 8.809 0 15.95-7.158 15.95-15.987 0.026-8.855-7.115-16.013-15.924-16.013z"></path>
        </symbol>
        <symbol id="icon-facebook" viewBox="0 0 32 32">
          <path d="M31.963 16.098c0-8.891-7.147-16.098-15.963-16.098s-15.963 7.207-15.963 16.098c0 8.035 5.837 14.695 13.469 15.902v-11.249h-4.053v-4.653h4.053v-3.546c0-4.035 2.383-6.263 6.029-6.263 1.746 0 3.573 0.314 3.573 0.314v3.962h-2.013c-1.983 0-2.602 1.241-2.602 2.515v3.018h4.427l-0.708 4.653h-3.72v11.249c7.631-1.208 13.469-7.867 13.469-15.902z"></path>
        </symbol>
        <symbol id="icon-search" viewBox="0 0 32 32">
          <path d="M12.667 22.667c-5.467 0-10-4.533-10-10s4.533-10 10-10 10 4.533 10 10-4.533 10-10 10zM12.667 5.333c-4 0-7.333 3.333-7.333 7.333s3.333 7.333 7.333 7.333 7.333-3.333 7.333-7.333-3.333-7.333-7.333-7.333z"></path>
          <path d="M17.771 19.656l1.886-1.886 9.334 9.334-1.886 1.886-9.334-9.334z"></path>
        </symbol>
        <symbol id="icon-globe" viewBox="0 0 32 32">
          <path d="M16 29.333c-7.333 0-13.333-6-13.333-13.333s6-13.333 13.333-13.333c7.333 0 13.333 6 13.333 13.333s-6 13.333-13.333 13.333zM16 5.333c-5.867 0-10.667 4.8-10.667 10.667s4.8 10.667 10.667 10.667 10.667-4.8 10.667-10.667-4.8-10.667-10.667-10.667z"></path>
          <path d="M4 14.933h24v2h-24v-2z"></path>
          <path d="M16 11.2c-5.867 0-8-1.333-9.333-2.133l-0.267-0.133 1.067-1.733 0.267 0.133c1.2 0.8 2.933 1.867 8.267 1.867 5.067 0 6.667-0.933 8-1.733l0.533-0.267 0.933 1.733-0.4 0.267c-1.6 0.933-3.467 2-9.067 2z"></path>
          <path d="M7.333 24.533l-0.933-1.733 0.4-0.267c1.467-0.933 3.6-2.133 9.2-2.133 5.867 0 8.133 1.467 9.6 2.4l0.133 0.133-1.067 1.733-0.133-0.133c-1.2-0.8-3.067-2-8.4-2-5.067 0-6.8 1.067-8.133 1.867l-0.667 0.133z"></path>
          <path d="M16.133 29.067c-3.467 0-6-5.6-6-13.067s2.667-13.067 6-13.067c3.467 0 6 5.6 6 13.067s-2.533 13.067-6 13.067zM16.133 5.067c-1.867 0-4 4.667-4 10.933s2.133 10.933 4 10.933 4-4.667 4-10.933-2.133-10.933-4-10.933z"></path>
        </symbol>
        <symbol id="icon-approved" viewBox="0 0 32 32">
          <path d="M16 30.578c-8.059 0-14.459-6.519-14.459-14.459s6.4-14.696 14.459-14.696 14.459 6.519 14.459 14.459-6.4 14.696-14.459 14.696zM16 3.793c-6.756 0-12.207 5.452-12.207 12.207s5.452 12.089 12.089 12.089c6.756 0 12.089-5.452 12.089-12.089 0.237-6.756-5.215-12.207-11.97-12.207z"></path>
          <path d="M14.933 23.23c-0.356 0-0.593-0.119-0.83-0.356l-4.978-5.096 1.659-1.659 3.793 3.793 5.689-10.43 2.133 1.067-6.4 11.97c-0.237 0.356-0.474 0.593-0.83 0.593-0.119 0.119-0.237 0.119-0.237 0.119z"></path>
        </symbol>
        <symbol id="icon-hagelklasse-4" viewBox="0 0 32 32">
          <path d="M17.517 3.747h-0.737v-1.597h-1.658v1.597h-0.737v-3.747h0.737v1.536h1.658v-1.536h0.737v3.747z"></path>
          <path d="M25.194 2.948l-0.921 3.869-0.676-0.43 0.246-0.798-1.228-0.798-0.614 0.491-0.614-0.43 3.194-2.334 0.614 0.43zM23.966 4.975l0.369-1.536-1.228 0.921 0.86 0.614z"></path>
          <path d="M30.108 11.178c0.307-0.246 0.369-0.676 0.246-1.044-0.246-0.553-0.798-0.798-1.658-0.43-0.798 0.368-1.106 0.921-0.86 1.474 0.184 0.368 0.553 0.798 1.106 0.553h0.061l-0.369-0.86 0.553-0.246 0.614 1.474-1.843 0.798-0.246-0.553 0.369-0.184c-0.369-0.061-0.676-0.368-0.921-0.86-0.43-0.983 0.061-1.843 1.106-2.334 1.044-0.43 2.027-0.123 2.457 0.921 0.369 0.798 0.123 1.536-0.491 1.904l-0.123-0.614z"></path>
          <path d="M31.52 20.514l-0.614-0.123 0.369-1.965-0.921-0.184-0.369 1.843-0.614-0.123 0.369-1.843-0.983-0.184-0.369 1.965-0.614-0.123 0.491-2.641 3.685 0.676-0.43 2.703z"></path>
          <path d="M24.641 25.797l-0.43-0.369 1.72-1.904 2.764 2.518-0.491 0.553-2.334-2.15-1.228 1.351z"></path>
          <path d="M19.605 31.754l-0.798 0.246 0.921-1.843-2.027-1.72 0.86-0.246 1.536 1.29 0.369-0.676-0.307-1.106 0.676-0.184 1.044 3.562-0.676 0.184-0.491-1.658-1.106 2.15z"></path>
          <path d="M11.067 28.131l0.184-0.553 2.457 0.676-1.044 3.562-0.676-0.184 0.86-3.010-1.781-0.491z"></path>
          <path d="M3.82 26.534l1.843-3.501 0.553 0.614-0.369 0.676 0.983 1.106 0.737-0.307 0.491 0.553-3.685 1.474-0.553-0.614zM5.478 24.814l-0.737 1.351 1.413-0.614-0.676-0.737z"></path>
          <path d="M1.363 19.9c-0.737 0.123-1.167-0.614-1.29-1.29-0.123-0.737 0.246-1.474 0.921-1.597l0.123 0.676c-0.43 0.123-0.491 0.491-0.43 0.86 0.061 0.307 0.246 0.676 0.553 0.614 0.246-0.061 0.369-0.307 0.369-0.614v-0.491c0-0.614 0.123-1.29 0.921-1.413 0.737-0.123 1.228 0.553 1.351 1.29 0.123 0.921-0.246 1.597-1.044 1.781l-0.123-0.676c0.553-0.184 0.614-0.553 0.553-0.983-0.061-0.307-0.246-0.737-0.614-0.676-0.307 0.061-0.369 0.369-0.369 0.737v0.491c0 0.614-0.246 1.167-0.921 1.29z"></path>
          <path d="M1.67 11.301c-0.676-0.307-0.676-1.167-0.369-1.781 0.307-0.676 0.983-1.106 1.658-0.86l-0.307 0.676c-0.43-0.123-0.737 0.184-0.86 0.491s-0.184 0.737 0.123 0.86c0.246 0.123 0.491-0.061 0.614-0.307l0.307-0.43c0.369-0.491 0.86-1.044 1.536-0.676 0.676 0.307 0.737 1.167 0.43 1.843-0.369 0.86-1.044 1.167-1.843 0.921l0.307-0.676c0.553 0.123 0.86-0.123 1.044-0.553 0.123-0.307 0.184-0.737-0.184-0.921-0.307-0.123-0.491 0.061-0.737 0.43l-0.246 0.43c-0.369 0.491-0.86 0.86-1.474 0.553v0z"></path>
          <path d="M8.303 1.965l0.368 0.491-1.658 1.106 0.491 0.798 1.536-1.044 0.368 0.43-1.536 1.044 0.553 0.86 1.658-1.106 0.307 0.491-2.273 1.536-2.088-3.071 2.273-1.536z"></path>
          <path d="M12.234 20.207c0.368 0 0.614-0.307 0.614-0.614 0-0.369-0.307-0.614-0.614-0.614-0.368 0-0.614 0.307-0.614 0.614-0.061 0.307 0.246 0.614 0.614 0.614z"></path>
          <path d="M10.944 22.726c0.368 0 0.614-0.307 0.614-0.614 0-0.369-0.307-0.614-0.614-0.614s-0.614 0.307-0.614 0.614c-0.061 0.369 0.246 0.614 0.614 0.614z"></path>
          <path d="M15.428 20.207c0.368 0 0.614-0.307 0.614-0.614 0-0.369-0.307-0.614-0.614-0.614-0.368 0-0.614 0.307-0.614 0.614-0.061 0.307 0.246 0.614 0.614 0.614z"></path>
          <path d="M14.138 22.726c0.368 0 0.614-0.307 0.614-0.614 0-0.369-0.307-0.614-0.614-0.614-0.368 0-0.614 0.307-0.614 0.614-0.061 0.369 0.246 0.614 0.614 0.614z"></path>
          <path d="M18.622 20.207c0.369 0 0.614-0.307 0.614-0.614 0-0.369-0.307-0.614-0.614-0.614s-0.614 0.307-0.614 0.614c-0.061 0.307 0.246 0.614 0.614 0.614z"></path>
          <path d="M17.332 22.726c0.369 0 0.614-0.307 0.614-0.614 0-0.369-0.307-0.614-0.614-0.614-0.369 0-0.614 0.307-0.614 0.614-0.061 0.369 0.246 0.614 0.614 0.614z"></path>
          <path d="M19.605 17.321h-8.967v-2.211c0.061-1.106 0.983-2.027 2.088-2.088h0.491v-0.553c0.061-1.781 1.474-3.132 3.194-3.132s3.132 1.29 3.255 3.010h0.43c0.737 0 1.29 0.246 1.72 0.676 0.43 0.491 0.676 1.106 0.676 1.904 0 0.737-0.307 1.351-0.921 1.781-0.553 0.369-1.228 0.614-1.965 0.614zM11.252 16.706h8.415c0.614 0 1.167-0.184 1.536-0.491 0.43-0.307 0.614-0.737 0.614-1.29 0-0.614-0.184-1.106-0.491-1.474-0.307-0.307-0.737-0.491-1.228-0.491h-1.044l-0.061-0.614c-0.123-1.351-1.29-2.457-2.703-2.457s-2.518 1.106-2.518 2.518v1.167h-1.106c-0.798 0-1.413 0.676-1.474 1.474v1.658z"></path>
          <path d="M17.701 14.802h-0.43v0.737h-0.737v-0.737h-1.474v-0.491l1.536-2.273h0.676v2.15h0.43v0.614zM16.534 13.635c0-0.061 0-0.123 0-0.184s0-0.184 0-0.246c0-0.061 0-0.123 0-0.184s0-0.123 0-0.123v0c0 0.061-0.061 0.123-0.123 0.184s-0.061 0.123-0.123 0.184l-0.614 0.983h0.86v-0.614z"></path>
        </symbol>
        <symbol id="icon-hagelklasse-5" viewBox="0 0 32 32">
          <path d="M12.185 20.185c0.369 0 0.615-0.308 0.615-0.615 0-0.369-0.308-0.615-0.615-0.615-0.369 0-0.615 0.308-0.615 0.615-0.062 0.308 0.246 0.615 0.615 0.615z"></path>
          <path d="M10.892 22.708c0.369 0 0.615-0.308 0.615-0.615 0-0.369-0.308-0.615-0.615-0.615-0.369 0-0.615 0.308-0.615 0.615-0.062 0.369 0.246 0.615 0.615 0.615z"></path>
          <path d="M15.385 20.185c0.369 0 0.615-0.308 0.615-0.615 0-0.369-0.308-0.615-0.615-0.615s-0.615 0.308-0.615 0.615c-0.062 0.308 0.246 0.615 0.615 0.615z"></path>
          <path d="M14.092 22.708c0.369 0 0.615-0.308 0.615-0.615 0-0.369-0.308-0.615-0.615-0.615-0.369 0-0.615 0.308-0.615 0.615-0.062 0.369 0.246 0.615 0.615 0.615z"></path>
          <path d="M18.585 20.185c0.369 0 0.615-0.308 0.615-0.615 0-0.369-0.308-0.615-0.615-0.615-0.369 0-0.615 0.308-0.615 0.615-0.062 0.308 0.246 0.615 0.615 0.615z"></path>
          <path d="M17.292 22.708c0.369 0 0.615-0.308 0.615-0.615 0-0.369-0.308-0.615-0.615-0.615-0.369 0-0.615 0.308-0.615 0.615-0.062 0.369 0.246 0.615 0.615 0.615z"></path>
          <path d="M19.569 17.292h-8.985v-2.215c0.062-1.108 0.985-2.031 2.092-2.092h0.492v-0.554c0.062-1.785 1.477-3.138 3.2-3.138s3.138 1.292 3.262 3.015h0.431c0.677 0 1.292 0.246 1.723 0.677 0.431 0.492 0.677 1.108 0.677 1.908 0 0.738-0.308 1.354-0.923 1.785-0.554 0.369-1.231 0.615-1.969 0.615zM11.2 16.677h8.431c0.615 0 1.169-0.185 1.538-0.492 0.431-0.308 0.615-0.738 0.615-1.292 0-0.615-0.185-1.108-0.492-1.477-0.308-0.308-0.738-0.492-1.231-0.492h-1.046l-0.062-0.615c-0.123-1.354-1.292-2.462-2.708-2.462s-2.523 1.108-2.523 2.523v1.169h-1.108c-0.8 0-1.415 0.677-1.477 1.477v1.662z"></path>
          <path d="M16.369 13.231c0.185 0 0.431 0.062 0.554 0.123 0.185 0.062 0.308 0.185 0.369 0.369 0.123 0.185 0.123 0.369 0.123 0.615s-0.062 0.492-0.185 0.677c-0.123 0.185-0.246 0.308-0.492 0.431-0.185 0.123-0.492 0.123-0.8 0.123-0.185 0-0.369 0-0.492-0.062-0.185-0.062-0.308-0.062-0.431-0.123v-0.615c0.123 0.062 0.246 0.123 0.431 0.185s0.308 0.062 0.492 0.062c0.123 0 0.246 0 0.369-0.062s0.185-0.123 0.246-0.185c0.062-0.062 0.062-0.185 0.062-0.308 0-0.185-0.062-0.308-0.185-0.369s-0.308-0.123-0.554-0.123c-0.062 0-0.185 0-0.308 0s-0.185 0.062-0.246 0.062l-0.308-0.185 0.123-1.785h2.092v0.615h-1.231l-0.062 0.677c0.062 0 0.123 0 0.185 0 0.062-0.123 0.123-0.123 0.246-0.123z"></path>
          <path d="M17.477 3.692h-0.738v-1.6h-1.662v1.6h-0.738v-3.754h0.738v1.538h1.662v-1.538h0.738v3.754z"></path>
          <path d="M25.169 2.892l-0.923 3.877-0.677-0.431 0.246-0.8-1.231-0.8-0.615 0.492-0.615-0.431 3.2-2.338 0.615 0.431zM23.938 4.923l0.369-1.538-1.231 0.923 0.862 0.615z"></path>
          <path d="M30.092 11.138c0.308-0.246 0.369-0.677 0.246-1.046-0.246-0.554-0.8-0.8-1.662-0.431-0.8 0.369-1.108 0.923-0.862 1.477 0.185 0.369 0.554 0.8 1.108 0.554h0.062l-0.369-0.862 0.554-0.246 0.615 1.477-1.846 0.8-0.246-0.554 0.369-0.185c-0.369-0.062-0.677-0.369-0.923-0.862-0.431-0.985 0.062-1.846 1.108-2.338 1.046-0.431 2.031-0.123 2.462 0.923 0.369 0.8 0.123 1.538-0.492 1.908l-0.123-0.615z"></path>
          <path d="M31.508 20.492l-0.615-0.123 0.369-1.969-0.923-0.185-0.369 1.846-0.615-0.123 0.369-1.846-0.985-0.185-0.369 1.969-0.615-0.123 0.492-2.646 3.692 0.677-0.431 2.708z"></path>
          <path d="M24.615 25.785l-0.431-0.369 1.723-1.908 2.769 2.523-0.492 0.554-2.338-2.154-1.231 1.354z"></path>
          <path d="M19.569 31.754l-0.8 0.246 0.923-1.846-2.031-1.723 0.862-0.246 1.538 1.292 0.369-0.677-0.308-1.108 0.677-0.185 1.046 3.569-0.677 0.185-0.492-1.662-1.108 2.154z"></path>
          <path d="M11.015 28.123l0.185-0.554 2.462 0.677-1.046 3.569-0.677-0.185 0.862-3.015-1.785-0.492z"></path>
          <path d="M3.754 26.523l1.846-3.508 0.554 0.615-0.369 0.677 0.985 1.108 0.738-0.308 0.492 0.554-3.692 1.477-0.554-0.615zM5.415 24.8l-0.738 1.354 1.415-0.615-0.677-0.738z"></path>
          <path d="M1.292 19.877c-0.738 0.123-1.169-0.615-1.292-1.292-0.123-0.738 0.246-1.477 0.923-1.6l0.123 0.677c-0.431 0.123-0.492 0.492-0.431 0.862 0.062 0.308 0.246 0.677 0.554 0.615 0.246-0.062 0.369-0.308 0.369-0.615v-0.492c0-0.615 0.123-1.292 0.923-1.415 0.738-0.123 1.231 0.554 1.354 1.292 0.123 0.923-0.246 1.6-1.046 1.785l-0.123-0.677c0.554-0.185 0.615-0.554 0.554-0.985-0.062-0.308-0.246-0.738-0.615-0.677-0.308 0.062-0.369 0.369-0.369 0.738v0.492c0 0.615-0.246 1.169-0.923 1.292z"></path>
          <path d="M1.6 11.262c-0.677-0.308-0.677-1.169-0.369-1.785 0.308-0.677 0.985-1.108 1.662-0.862l-0.308 0.677c-0.431-0.123-0.738 0.185-0.862 0.492s-0.185 0.738 0.123 0.862c0.246 0.123 0.492-0.062 0.615-0.308l0.308-0.431c0.369-0.492 0.862-1.046 1.538-0.677 0.677 0.308 0.738 1.169 0.431 1.846-0.369 0.862-1.046 1.169-1.846 0.923l0.308-0.677c0.554 0.123 0.862-0.123 1.046-0.554 0.123-0.308 0.185-0.738-0.185-0.923-0.308-0.123-0.492 0.062-0.738 0.431l-0.246 0.431c-0.369 0.492-0.862 0.862-1.477 0.554v0z"></path>
          <path d="M8.246 1.908l0.369 0.492-1.662 1.108 0.492 0.8 1.538-1.046 0.369 0.431-1.538 1.046 0.554 0.862 1.662-1.108 0.308 0.492-2.277 1.538-2.092-3.077 2.277-1.538z"></path>
        </symbol>
        <symbol id="icon-plus" viewBox="0 0 32 32">
          <path d="M16.706 6.818h-1.474v8.415h-8.415v1.474h8.415v8.415h1.474v-8.415h8.415v-1.474h-8.415z"></path>
          <path d="M28.131 0h-24.322c-2.088 0-3.808 1.72-3.808 3.808v24.384c0 2.088 1.72 3.808 3.808 3.808h24.384c2.088 0 3.808-1.72 3.808-3.808v-24.384c-0.061-2.088-1.781-3.808-3.869-3.808zM30.465 28.131c0 1.29-1.044 2.273-2.273 2.273h-24.384c-1.29 0-2.273-1.044-2.273-2.273v-24.322c0-1.29 1.044-2.273 2.273-2.273h24.384c1.29 0 2.273 1.044 2.273 2.273v24.322z"></path>
        </symbol>
      </defs>
    </svg>


  </div>

</template>
<script>
    import {DachziegelDataMixin} from "./mixins/DachziegelDataMixin";
    import AppViewContent from "./components/AppViewContent";
    import AppViewLoader from "./components/AppViewLoader";

    export default {
        mixins: [DachziegelDataMixin],
        components: {AppViewContent, AppViewLoader}
    }
</script>

