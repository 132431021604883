import Vue from 'vue'
import Vuex from 'vuex'
import {getField, updateField} from 'vuex-map-fields';

Vue.use(Vuex)
/**
 * @see @/mixins/DachziegelDataMixin
 */
export default new Vuex.Store({
    state: {
        DachziegelData: {},
        DachziegelDataVersion: false,
        DachziegelAppVersion: 1,
        renderComponent: false,
        networkState: false,
        onLine: navigator.onLine,
        isCordovaApp: false,
        imageDownloads: false,
        imageDownloadsVersion: false,
        headerShowGoBack: false,
    },
    getters: {
        getField
    },
    mutations: {
        updateField
    }
})
