<template>
    <div>
        <header>
          <div class="navbar-main fixed-top">
            <div class="container-fluid">
              <div class="navbar-logo">
                <img v-if="DachziegelData.data.options.current_lang === 'da'" src="../assets/images/logo-jacobi-walther-da.jpg" height="47" alt="Logo Jacobi Walther">
                <img v-else src="../assets/images/logo-jacobi-walther.svg" width="125" height="47" alt="Logo Jacobi Walther">
              </div>
            </div>
          </div>
        </header>
        <main>
            <div class="info-section">
                <div class="container-fluid">
                    <h1>{{DachziegelData.data.options.lang.landing_title}}</h1>
                    <p>{{DachziegelData.data.options.lang.landing_offline_info}}</p>
                     <div class="button-container">
                       <button class="btn btn-primary" @click.prevent="preLoadImages">
                          {{DachziegelData.data.options.lang.landing_button_download}}
                       </button>
                     </div>
                      <div class="button-container">
                        <button class="btn btn-primary" @click.prevent="preLoadImagesSkip">
                            {{DachziegelData.data.options.lang.landing_button_skip_download}}
                        </button>
                      </div>
                </div>
            </div>
        </main>
        <footer class="page__footer">
            <div class="footer-info">
                <div class="container-fluid">
                    <p>{{DachziegelData.data.options.settings.app_footer_copy_text}}</p>
                    <router-link
                            to="/contentImpressum"
                            @click.native="preLoadImagesSkip()"
                    >{{DachziegelData.data.options.lang.general_imprint}}</router-link>
                    <p>v. {{DachziegelAppVersion}}#{{DachziegelDataVersion}}</p>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
    import {DachziegelDataMixin} from "../mixins/DachziegelDataMixin";
    import AppFooter from "./AppFooter";

    export default {
        name: "AppViewLoader",
        components: {AppFooter},
        mixins: [DachziegelDataMixin],
        methods: {
            preLoadImages() {
                this.$DKFuctions.preLoadImages()
            },
            preLoadImagesSkip() {
                this.$DKFuctions.preLoadImagesSkip()
            },
        }
    }
</script>
