import {mapFields} from 'vuex-map-fields';

/**
 * @see @/store/index.js
 */
export const DachziegelDataMixin = {
    computed: {
        ...mapFields([
            'DachziegelData',
            'DachziegelDataVersion',
            'DachziegelAppVersion',
            'renderComponent',
            'networkState',
            'onLine',
            'imageDownloads',
            'imageDownloadsVersion',
            'isCordovaApp',
            'headerShowGoBack',
        ])
    },
}
