<template>
    <div>
        <AppHeader/>
        <div id="offside-container">
          <div v-show="headerShowGoBack" class="go-back">
            <div class="container-fluid">
              <router-link to="/ziegelliste">
                <svg class="icon" aria-hidden="true"><use xlink:href="#icon-chevron-left"></use></svg>
                <span class="go-back__label">{{DachziegelData.data.options.lang.nav_back_to_overview}}</span>
              </router-link>
            </div>
          </div>
            <router-view/>
        </div>
        <AppFooter/>
    </div>
</template>
<script>
    import {DachziegelDataMixin} from "../mixins/DachziegelDataMixin";
    import AppFooter from "./AppFooter";
    import AppHeader from "./AppHeader";

    export default {
        name: "AppViewContent",
        components: {AppHeader, AppFooter},
        mixins: [DachziegelDataMixin]
    }
</script>
<style scoped>
</style>
